import logo from '@/images/logo.svg';

export const WordmarkLogo: React.FC = () => (
  <svg viewBox='0 0 6636 1920' className='logo logo--wordmark' role='img'>
    <title>K21CO</title>
    <use xlinkHref='#logo-symbol-wordmark' />
  </svg>
);

export const SymbolLogo: React.FC = () => (
  <img src={logo} alt='K21CO' className='logo logo--icon' />
);
